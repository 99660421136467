import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  NumberField,
  DateField,
  BooleanField,
  BooleanInput,
} from "react-admin";

export const DiscountCampaignList = (props: ResourceComponentProps) => (
  <List
    {...props}
    filters={
      <Filter {...props}>
        <ReferenceInput
          label="Organization"
          reference="Organization"
          source="organizationId"
        >
          <AutocompleteInput />
        </ReferenceInput>
        <BooleanInput source="active" defaultValue={true} />
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Organization"
        reference="Organization"
        source="organization.id"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="percentageDiscounted"
        label="Discount Percentage (%)"
      />
      <BooleanField source="active" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
