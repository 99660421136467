import { gql, useMutation } from "@apollo/client";
import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  DateField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  Button,
  useNotify,
} from "react-admin";
import { printUser } from "../../helpers/print-user";

export const AlayaCareClientList = (props: ResourceComponentProps) => (
  <List
    {...props}
    bulkActionButtons={<AlayaCareClientListActions />}
    title="AlayaCare Clients"
    filters={
      <Filter {...props}>
        <ReferenceInput
          label="AlayaCareEnvironment"
          reference="AlayaCareEnvironment"
          source="alayaCareEnvironmentId"
        >
          <AutocompleteInput optionText="baseApiUrl" />
        </ReferenceInput>
        <ReferenceInput
          label="Linked Client User"
          reference="User"
          source="userId"
          filterToQuery={(q: string) => ({ q })}
        >
          <AutocompleteInput optionText={printUser} />
        </ReferenceInput>
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="AlayaCareEnvironment"
        reference="AlayaCareEnvironment"
        source="alayaCareEnvironment.id"
      >
        <TextField source="baseApiUrl" />
      </ReferenceField>
      <ReferenceField
        sortable={false}
        label="User"
        reference="User"
        source="user.id"
      >
        <FunctionField render={printUser} />
      </ReferenceField>
      <TextField source="alayaCareClientId" label="AlayaCare Client id" />
      <TextField
        source="alayaCareClientExternalId"
        label="AlayaCare Client external id"
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

const AlayaCareClientListActions = ({ selectedIds }: any) => {
  const notify = useNotify();
  const [syncClientVisits, { loading: syncingClientVisits }] = useMutation(
    gql`
      mutation syncAlayaCareClientsVisits($ids: [ID!]!) {
        syncAlayaCareClientVisits(ids: $ids)
      }
    `
  );

  return (
    <>
      <Button
        label="Sync AlayaCare visits"
        disabled={syncingClientVisits}
        variant="outlined"
        onClick={async () => {
          try {
            await syncClientVisits({ variables: { ids: selectedIds } });
            notify("Queued AlayaCare visit sync for Clients");
          } catch (error) {
            console.error(error);
            notify("An error occurred syncing Client visits", "error");
          }
        }}
      />
    </>
  );
};
