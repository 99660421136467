import React from "react";
import {
  Edit,
  NumberInput,
  ReferenceField,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextField,
} from "react-admin";

export const AlayaCareOvertimeRuleEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceField
        label="AlayaCare Environment"
        source="alayaCareEnvironment.id"
        reference="AlayaCareEnvironment"
      >
        <TextField source="baseApiUrl" />
      </ReferenceField>
      <NumberInput source="hours" validate={[required()]} />
      <NumberInput source="days" validate={[required()]} />
    </SimpleForm>
  </Edit>
);
