import React from "react";
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from "react-admin";
import { printUser } from "../../helpers/print-user";

const ClientBookingHourCreditTransactionCreate = (
  props: ResourceComponentProps
) => (
  <Create
    {...props}
    title="Create Booking Hour Credit Transaction"
    successMessage="Client Booking Hour Credit Transaction created."
  >
    <SimpleForm>
      <ReferenceInput
        label="Client"
        reference="User"
        source="clientUserId"
        filter={{ role: "CLIENT" }}
      >
        <AutocompleteInput optionText={printUser} validate={[required()]} />
      </ReferenceInput>
      <NumberInput
        source="amount"
        label="Amount (in hours)"
        helperText="Set to negative if you want to deduct credits"
        validate={[required()]}
      />
      <TextInput source="description" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default ClientBookingHourCreditTransactionCreate;
