import React from "react";
import {
  Edit,
  NumberInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { PRO_TYPE_CHOICES } from "../../constants/pro-types";
import { hoursToMilliseconds, millisecondsToHours } from "../../helpers/dates";

const ServiceCodeEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="serviceCode" />
      <SelectInput
        source="proType"
        choices={PRO_TYPE_CHOICES}
        validate={[required()]}
      />
      <TextInput
        source="minDuration"
        label="Min Duration (hours)"
        validate={[required()]}
        format={millisecondsToHours}
        parse={hoursToMilliseconds}
      />
      <TextInput
        source="maxDuration"
        label="Max Duration (hours)"
        validate={[required()]}
        format={millisecondsToHours}
        parse={hoursToMilliseconds}
      />
      <NumberInput
        source="hourlyRate"
        label="Hourly Rate (dollars)"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

export default ServiceCodeEdit;
