import React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { hoursToMilliseconds, millisecondsToHours } from "../../helpers/dates";

export const AlayaCareCancelCodeCreate = (props: ResourceComponentProps) => (
  <Create
    {...props}
    title="Create AlayaCare cancel code"
    successMessage="AlayaCare cancel code created."
  >
    <SimpleForm>
      <ReferenceInput
        label="AlayaCareEnvironment"
        reference="AlayaCareEnvironment"
        source="alayaCareEnvironmentId"
      >
        <SelectInput optionText="baseApiUrl" validate={[required()]} />
      </ReferenceInput>
      <NumberInput source="alayaCareCancelCodeId" validate={[required()]} />
      <NumberInput
        source="minDuration"
        label="Min duration (hours)"
        validate={[required()]}
        format={millisecondsToHours}
        parse={hoursToMilliseconds}
      />
      <NumberInput
        source="maxDuration"
        label="Max duration (hours)"
        validate={[required()]}
        format={millisecondsToHours}
        parse={hoursToMilliseconds}
      />
    </SimpleForm>
  </Create>
);
