import React from "react";
import {
  BooleanInput,
  Edit,
  FunctionField,
  ReferenceField,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";
import { printUser } from "../../helpers/print-user";

const UserRelationshipEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <ReferenceField label="User" source="user.id" reference="User">
        <FunctionField render={printUser} />
      </ReferenceField>
      <ReferenceField
        label="Related user"
        source="relatedUser.id"
        reference="User"
      >
        <FunctionField render={printUser} />
      </ReferenceField>
      <BooleanInput source="favorited" />
    </SimpleForm>
  </Edit>
);

export default UserRelationshipEdit;
