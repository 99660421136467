import React from "react";
import {
  Edit,
  PasswordInput,
  ReferenceField,
  required,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";

const highlightOnFocus = (event: any) => {
  event.target.select();
};

export const AlayaCareEnvironmentEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <ReferenceField
        label="Organization"
        source="organization.id"
        reference="Organization"
      >
        <TextField source="name" />
      </ReferenceField>

      <TextInput
        source="baseApiUrl"
        validate={[required()]}
        placeholder="https://example.alayacare.com"
      />
      <PasswordInput
        source="publicAccessKey"
        label="API public access key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />

      <PasswordInput
        source="privateAccessKey"
        label="API private access key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />

      <TextInput
        source="schedulerQueueUrl"
        validate={[required()]}
        placeholder="https://sqs.REGION.amazonaws.com/ACCOUNT/QUEUE"
      />
      <PasswordInput
        source="schedulerQueuePublicAccessKey"
        label="Scheduler queue public key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />
      <PasswordInput
        source="schedulerQueuePrivateAccessKey"
        label="Scheduler queue private key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />

      <TextInput
        source="employeeQueueUrl"
        validate={[required()]}
        placeholder="https://sqs.REGION.amazonaws.com/ACCOUNT/QUEUE"
      />
      <PasswordInput
        source="employeeQueuePublicAccessKey"
        label="Employee queue public key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />
      <PasswordInput
        source="employeeQueuePrivateAccessKey"
        label="Employee queue private key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />

      <TextInput
        source="patientQueueUrl"
        validate={[required()]}
        placeholder="https://sqs.REGION.amazonaws.com/ACCOUNT/QUEUE"
      />
      <PasswordInput
        source="patientQueuePublicAccessKey"
        label="Patient queue public key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />
      <PasswordInput
        source="patientQueuePrivateAccessKey"
        label="Patient queue private key"
        validate={[required()]}
        onFocus={highlightOnFocus}
      />

      <TextInput
        source="flatFileS3BucketName"
        label="Availability flat file S3 bucket"
        placeholder="s3-bucket-name"
      />
      <TextInput
        source="flatFileRegion"
        label="Availability flat file AWS region"
        placeholder="ca-central-1"
      />
      <PasswordInput
        source="flatFilePublicAccessKey"
        label="Availability flat file public key"
        onFocus={highlightOnFocus}
      />
      <PasswordInput
        source="flatFilePrivateAccessKey"
        label="Availability flat file private key"
        onFocus={highlightOnFocus}
      />
    </SimpleForm>
  </Edit>
);
