import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";

const DiscountCampaignEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <ReferenceInput
        label="Organization"
        reference="Organization"
        source="organization.id"
      >
        <AutocompleteInput validate={[required()]} />
      </ReferenceInput>
      <NumberInput
        source="percentageDiscounted"
        label="Discount Percentage (%)"
        validate={[required()]}
      />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

export default DiscountCampaignEdit;
