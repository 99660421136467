export default {
  cognito: {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
    },
  },
  server: {
    httpUri: process.env.REACT_APP_SERVER_HTTP_URI,
  },
};
