import React from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  required,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useEditContext,
} from "react-admin";
import { useFormState } from "react-final-form";

const UserEdit = (props: ResourceComponentProps) => {
  return (
    <Edit
      {...props}
      transform={(values) => {
        return {
          ...values,
          description: values.description ?? "",
          requiredCaregiverTypes: values.enabledRequiredCaregiverTypes
            ? values.requiredCaregiverTypes
            : null,
        };
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />

        <ReferenceArrayInput
          label="Regions"
          source="regionsIds"
          reference="Region"
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Organizations"
          source="organizationsIds"
          reference="Organization"
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <NumberInput
          source="maxWorkableHoursForDay"
          label="Max workable hours in a day (defaults to 8)"
        />
        <RequiredCaregiverTypesInputs />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;

const RequiredCaregiverTypesInputs = () => {
  const context = useEditContext();
  const formState = useFormState();

  return (
    <>
      <BooleanInput
        source="enabledRequiredCaregiverTypes"
        label="Filter required caregiver types?"
        defaultValue={Array.isArray(
          (context.record as any).requiredCaregiverTypes
        )}
      />
      {formState.values.enabledRequiredCaregiverTypes && (
        <AutocompleteArrayInput
          source="requiredCaregiverTypes"
          choices={[
            { id: "PSW", name: "PSWs" },
            { id: "COMPANION", name: "Companions" },
          ]}
        />
      )}
    </>
  );
};
