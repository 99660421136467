import { gql, useMutation } from "@apollo/client";
import React, { useCallback, useMemo } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  required,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import SendIcon from "@material-ui/icons/Send";

import { printUser } from "../helpers/print-user";

const SEND_PUSH_NOTIFICATIONS_MUTATION = gql`
  mutation SendPushNotifications(
    $userIds: [ID!]!
    $badge: Int
    $message: String!
    $ignoreQuietHours: Boolean
  ) {
    sendPushNotifications(
      userIds: $userIds
      badge: $badge
      message: $message
      ignoreQuietHours: $ignoreQuietHours
    )
  }
`;

export const PushNotificationsPage = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [sendPushNotifications, { loading: sending }] = useMutation(
    SEND_PUSH_NOTIFICATIONS_MUTATION,
    {
      onCompleted: () => {
        notify("The push notification(s) have been sent");
        redirect("/");
      },
      onError: (error) => notify(error.message, "error"),
    }
  );

  const handleSave = useCallback(
    (values: any) => {
      sendPushNotifications({
        variables: values,
      });
    },
    [sendPushNotifications]
  );

  const saveContext = useMemo(
    () => ({
      saving: sending,
      save: handleSave,
    }),
    [sending, handleSave]
  );

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        initialValues={{
          userIds: [],
          message: "",
          badge: 0,
          ignoreQuietHours: false,
        }}
        saving={sending}
        save={handleSave}
        toolbar={
          <Toolbar>
            <SaveButton label="Send Notification(s)" icon={<SendIcon />} />
          </Toolbar>
        }
      >
        <ReferenceArrayInput
          label="Recipient User(s)"
          source="userIds"
          reference="User"
        >
          <AutocompleteArrayInput
            optionText={printUser}
            validate={[required()]}
            helperText="The user(s) to send the push notifications to. Note that only CLIENTs and PROs can be push notified."
          />
        </ReferenceArrayInput>
        <TextInput
          source="message"
          multiline
          validate={[required()]}
          helperText="The content of the message that will be displayed in the push notification"
          style={{ width: "500px", maxWidth: "100%" }}
        />
        <NumberInput
          source="badge"
          validate={[required(), minValue(0)]}
          helperText="Sets the app badge icon on the user's home page"
          style={{ width: "500px", maxWidth: "100%" }}
        />
        <BooleanInput
          source="ignoreQuietHours"
          helperText="If turned on, will send the push notification even if the user is during their defined quiet hours. This only applies to clients."
          style={{ width: "500px", maxWidth: "100%" }}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};
