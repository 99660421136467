import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  DateField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  NumberField,
} from "react-admin";

export const AlayaCareOvertimeRuleList = (props: ResourceComponentProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    title="AlayaCare Overtime Rule"
    filters={
      <Filter {...props}>
        <ReferenceInput
          label="AlayaCareEnvironment"
          reference="AlayaCareEnvironment"
          source="alayaCareEnvironmentId"
        >
          <AutocompleteInput optionText="baseApiUrl" />
        </ReferenceInput>
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="AlayaCareEnvironment"
        reference="AlayaCareEnvironment"
        source="alayaCareEnvironment.id"
      >
        <TextField source="baseApiUrl" />
      </ReferenceField>
      <NumberField source="hours" />
      <NumberField source="days" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
