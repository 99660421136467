import React from "react";
import buildGraphQLProvider from "ra-data-graphql-simple";
import Amplify, { Auth } from "aws-amplify";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Admin, Resource, DataProvider } from "react-admin";
import { createBrowserHistory as createHistory } from "history";
import { Helmet } from "react-helmet";
import IdleTimer from "react-idle-timer";
import { Route } from "react-router-dom";

import BusinessIcon from "@material-ui/icons/Business";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import PeopleIcon from "@material-ui/icons/People";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SettingsIcon from "@material-ui/icons/Settings";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import RedeemIcon from "@material-ui/icons/Redeem";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import config from "./config";
import authProvider from "./authProvider";
import { OrganizationList } from "./resource/organization/organization-list";
import { RegionList } from "./resource/region/region-list";
import OrganizationCreate from "./resource/organization/organization-create";
import OrganizationEdit from "./resource/organization/organization-edit";
import RegionCreate from "./resource/region/region-create";
import RegionEdit from "./resource/region/region-edit";
import { UserList } from "./resource/user/user-list";
import UserEdit from "./resource/user/user-edit";
import { UserRelationshipList } from "./resource/user-relationship/user-relationship-list";
import UserRelationshipCreate from "./resource/user-relationship/user-relationship-create";
import UserRelationshipEdit from "./resource/user-relationship/user-relationship-edit";
import { ServiceCodeList } from "./resource/service-code/service-code-list";
import ServiceCodeCreate from "./resource/service-code/service-code-create";
import ServiceCodeEdit from "./resource/service-code/service-code-edit";
import { AlayaCareEnvironmentList } from "./resource/alaya-care-environment/alaya-care-environment-list";
import AlayaCareEnvironmentCreate from "./resource/alaya-care-environment/alaya-care-environment-create";
import { AlayaCareEnvironmentEdit } from "./resource/alaya-care-environment/alaya-care-environment-edit";
import { AlayaCareOvertimeRuleList } from "./resource/alaya-care-overtime-rule/alaya-care-overtime-rule-list";
import { AlayaCareOvertimeRuleCreate } from "./resource/alaya-care-overtime-rule/alaya-care-overtime-rule-create";
import { AlayaCareOvertimeRuleEdit } from "./resource/alaya-care-overtime-rule/alaya-care-overtime-rule-edit";
import { AlayaCareEmployeeList } from "./resource/alaya-care-employee/alaya-care-employee-list";
import { AlayaCareEmployeeEdit } from "./resource/alaya-care-employee/alaya-care-employee-edit";
import { AlayaCareClientList } from "./resource/alaya-care-client/alaya-care-client-list";
import { AlayaCareCancelCodeList } from "./resource/alaya-care-cancel-code/alaya-care-cancel-code-list";
import { AlayaCareCancelCodeCreate } from "./resource/alaya-care-cancel-code/alaya-care-cancel-code-create";
import { AlayaCareCancelCodeEdit } from "./resource/alaya-care-cancel-code/alaya-care-cancel-code-edit";
import { BookableAvailabilitiesList } from "./resource/bookable-availabilities/bookable-availabilities-list";
import { ClientBookingHourCreditTransactionList } from "./resource/client-booking-hour-credit-transaction/client-booking-hour-credit-transaction-list";
import ClientBookingHourCreditTransactionCreate from "./resource/client-booking-hour-credit-transaction/client-booking-hour-credit-transaction-create";
import { DiscountCampaignList } from "./resource/discount-campaign/discount-campaign-list";
import DiscountCampaignCreate from "./resource/discount-campaign/discount-campaign-create";
import DiscountCampaignEdit from "./resource/discount-campaign/discount-campaign-edit";
import { AppLayout } from "./layout";
import { PushNotificationsPage } from "./custom-pages/push-notifications";

const INACTIVITY_TIMEOUT = 60 * 1000 * 10;

Amplify.configure(config.cognito);

const httpLink = createHttpLink({
  uri: config.server.httpUri,
});

const authLink = setContext(async (_, { headers }) => {
  const newHeaders = { ...headers };
  let idToken = "";
  try {
    idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    newHeaders["id-token"] = `Bearer ${idToken}`;
  } catch (error) {
    // User is not signed in
    console.error(error);
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: newHeaders,
  };
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const history = createHistory();

type State = {
  dataProvider?: DataProvider;
};
class App extends React.Component<{}, State> {
  state: State = {};

  componentDidMount() {
    buildGraphQLProvider({
      client: apolloClient,
    }).then((dataProvider: DataProvider) => this.setState({ dataProvider }));
  }

  // Triggered if user is inactive for 'INACTIVITY_TIMEOUT' ms
  handleOnIdle = () => {
    // User is signed-out
    Auth.signOut();
    // Attempt to navigate to the login page. User is signed-out regardless and will be
    // redirected to the login page once they try to navigate to a new page (if our navigation fails).
    if (
      window?.location?.origin &&
      window?.location?.href &&
      window?.location?.pathname !== "/login"
    ) {
      window.location.href = `${window.location.origin}/login`;
    }
  };

  renderApp() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <ApolloProvider client={apolloClient}>
        <IdleTimer timeout={INACTIVITY_TIMEOUT} onIdle={this.handleOnIdle} />
        <Admin
          title="Caribou Superuser Dashboard"
          dataProvider={dataProvider}
          authProvider={authProvider}
          history={history}
          layout={AppLayout}
          customRoutes={[
            <Route
              path="/push-notifications"
              exact
              component={PushNotificationsPage}
            />,
          ]}
        >
          <Resource
            name="Region"
            list={RegionList}
            create={RegionCreate}
            edit={RegionEdit}
            icon={RoomOutlinedIcon}
          />
          <Resource
            name="Organization"
            list={OrganizationList}
            create={OrganizationCreate}
            edit={OrganizationEdit}
            icon={BusinessIcon}
          />
          <Resource
            name="User"
            list={UserList}
            edit={UserEdit}
            icon={PeopleIcon}
          />
          <Resource
            name="UserRelationship"
            list={UserRelationshipList}
            create={UserRelationshipCreate}
            edit={UserRelationshipEdit}
            icon={FavoriteBorderIcon}
            options={{
              label: "Favourites",
            }}
          />
          <Resource
            name="ClientBookingHourCreditTransaction"
            options={{ label: "Booking Hour Credits" }}
            list={ClientBookingHourCreditTransactionList}
            create={ClientBookingHourCreditTransactionCreate}
            icon={RedeemIcon}
          />
          <Resource
            name="ServiceCode"
            options={{ label: "Service Codes" }}
            list={ServiceCodeList}
            create={ServiceCodeCreate}
            edit={ServiceCodeEdit}
            icon={LocalOfferIcon}
          />
          <Resource
            name="DiscountCampaign"
            options={{ label: "Discount Campaigns" }}
            list={DiscountCampaignList}
            create={DiscountCampaignCreate}
            edit={DiscountCampaignEdit}
            icon={MoneyOffIcon}
          />
          <Resource
            name="AlayaCareEnvironment"
            options={{ label: "AlayaCare Environments" }}
            list={AlayaCareEnvironmentList}
            create={AlayaCareEnvironmentCreate}
            edit={AlayaCareEnvironmentEdit}
            icon={SettingsIcon}
          />
          <Resource
            name="AlayaCareOvertimeRule"
            options={{ label: "AlayaCare Overtime Rules" }}
            list={AlayaCareOvertimeRuleList}
            create={AlayaCareOvertimeRuleCreate}
            edit={AlayaCareOvertimeRuleEdit}
            icon={ScheduleIcon}
          />
          <Resource
            name="AlayaCareCancelCode"
            options={{ label: "AlayaCare Cancel Codes" }}
            list={AlayaCareCancelCodeList}
            create={AlayaCareCancelCodeCreate}
            edit={AlayaCareCancelCodeEdit}
            icon={CancelPresentationIcon}
          />
          <Resource
            name="AlayaCareEmployee"
            options={{ label: "AlayaCare Employees" }}
            list={AlayaCareEmployeeList}
            edit={AlayaCareEmployeeEdit}
            icon={AssignmentIndIcon}
          />
          <Resource
            name="AlayaCareClient"
            options={{ label: "AlayaCare Clients" }}
            list={AlayaCareClientList}
            icon={PermContactCalendarIcon}
          />

          {/* Temporary resource in order to provide visibility into bookable availabilities */}
          <Resource
            name="BookableAvailability"
            options={{ label: "Bookable Availabilities" }}
            list={BookableAvailabilitiesList}
            icon={EventAvailableIcon}
          />
        </Admin>
      </ApolloProvider>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Caribou Superuser Dashboard</title>
        </Helmet>
        {this.renderApp()}
      </>
    );
  }
}

export default App;
