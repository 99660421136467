import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  TextInput,
  DateField,
} from "react-admin";

export const RegionList = (props: ResourceComponentProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={
      <Filter {...props}>
        <TextInput label="Search by name" source="q" alwaysOn />
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="timeZone" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
