import React from "react";
import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from "react-admin";

const OrganizationCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceArrayInput
        label="Regions"
        source="regionsIds"
        reference="Region"
      >
        <AutocompleteArrayInput validate={[required()]} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default OrganizationCreate;
