import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SimpleForm,
  useNotify,
  useRedirect,
} from "react-admin";

const DiscountCampaignCreate = (props: ResourceComponentProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      {...props}
      onSuccess={() => {
        redirect("/DiscountCampaign");
        notify("Discount campaign created.");
      }}
    >
      <SimpleForm>
        <ReferenceInput
          label="Organization"
          reference="Organization"
          source="organizationId"
        >
          <AutocompleteInput validate={[required()]} />
        </ReferenceInput>
        <NumberInput
          source="percentageDiscounted"
          label="Discount Percentage (%)"
          validate={[required()]}
        />
        <BooleanInput source="active" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default DiscountCampaignCreate;
