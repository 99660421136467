import React from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  required,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from "react-admin";

const OrganizationEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput source="name" validate={[required()]} />
      <ReferenceArrayInput
        label="Regions"
        source="regionsIds"
        reference="Region"
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <TextInput source="email" type="email" />
      <TextInput source="phone" type="tel" />
      <TextInput source="logoUrl" />
      <ArrayInput source="adminEmails">
        <SimpleFormIterator>
          <TextInput source={""} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="availabilityRequestEmails">
        <SimpleFormIterator>
          <TextInput source={""} />
        </SimpleFormIterator>
      </ArrayInput>
      <NumberInput source="hideAvailabilityBefore" />
      <NumberInput source="hideAvailabilityAfter" />
      <BooleanInput
        source="favoritesDisabled"
        helperText="Clients will not be able to edit favourites. However, clients will continue only receive new availability notifications from favorites."
      />
      <BooleanInput
        source="enableClientQuietHours"
        label="Client quiet hours enabled"
      />
      <NumberInput
        source="newClientBookingHourCreditPromo"
        style={{ width: "400px" }}
        helperText="Clients are automatically credited this amount of free bookings when they first sign up"
      />
      <BooleanInput
        source="isForQA"
        label="Is for QA"
        helperText="If true, activity for this organization will not trigger slack messages in our 'live users' slack channel."
      />
    </SimpleForm>
  </Edit>
);

export default OrganizationEdit;
