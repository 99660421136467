import React from "react";
import {
  AutocompleteInput,
  Edit,
  required,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { ALL_TIMEZONES } from "../../constants/timezones";

const RegionEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput source="name" validate={[required()]} />
      <AutocompleteInput
        source="timeZone"
        allowEmpty
        choices={ALL_TIMEZONES.map((timezone) => ({
          id: timezone,
          name: timezone,
        }))}
      />
    </SimpleForm>
  </Edit>
);

export default RegionEdit;
