import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  TextInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DateField,
} from "react-admin";

export const OrganizationList = (props: ResourceComponentProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={
      <Filter {...props}>
        <TextInput label="Search by name" source="q" alwaysOn />
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceArrayField
        sortable={false}
        sort={{ field: "name", order: "ASC" }}
        label="Regions"
        reference="Region"
        source="regionsIds"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
