export function millisecondsToHours(ms: number | string | undefined) {
  if (ms == null || ms === "") {
    return ms;
  }

  return Number(ms) / 1000 / 60 / 60;
}

export function hoursToMilliseconds(hours: number | string | undefined) {
  if (hours == null || hours === "") {
    return hours;
  }

  return Number(hours) * 1000 * 60 * 60;
}
