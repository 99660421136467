import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  NumberField,
  FunctionField,
  DateField,
} from "react-admin";
import { PRO_TYPE_CHOICES } from "../../constants/pro-types";
import { millisecondsToHours } from "../../helpers/dates";

export const ServiceCodeList = (props: ResourceComponentProps) => (
  <List
    {...props}
    filters={
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SelectInput source="proType" choices={PRO_TYPE_CHOICES} />
        <ReferenceInput
          label="Organization"
          reference="Organization"
          source="organizationId"
        >
          <AutocompleteInput />
        </ReferenceInput>
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="serviceCode" />
      <TextField source="proType" />
      <FunctionField
        source="minDuration"
        label="Min Duration (hrs)"
        render={(serviceCode: any) =>
          millisecondsToHours(serviceCode.minDuration)
        }
      />
      <FunctionField
        source="maxDuration"
        label="Max Duration (hrs)"
        render={(serviceCode: any) =>
          millisecondsToHours(serviceCode.maxDuration)
        }
      />
      <NumberField
        source="hourlyRate"
        options={{ style: "currency", currency: "CAD" }}
      />

      <ReferenceField
        sortable={false}
        label="Organization"
        reference="Organization"
        source="organization.id"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
