import React from "react";
import { useSelector } from "react-redux";
import { getResources, MenuItemLink, MenuProps } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import NotificationsIcon from "@material-ui/icons/Notifications";

export const AppMenu = ({ onMenuClick, logout }: MenuProps) => {
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  const commonLinkProps = {
    onClick: onMenuClick,
    sidebarIsOpen: open,
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      {resources.map((resource) => (
        <MenuItemLink
          {...commonLinkProps}
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        />
      ))}
      <MenuItemLink
        {...commonLinkProps}
        to="/push-notifications"
        primaryText="Push Notifications"
        leftIcon={<NotificationsIcon />}
      />
    </div>
  );
};
