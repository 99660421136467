import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SimpleForm,
} from "react-admin";
import { printUser } from "../../helpers/print-user";

const UserRelationshipCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="User" source="user.id" reference="User">
        <AutocompleteInput optionText={printUser} validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput
        label="Related User"
        source="relatedUser.id"
        reference="User"
      >
        <AutocompleteInput optionText={printUser} validate={[required()]} />
      </ReferenceInput>
      <BooleanInput source="favorited" />
    </SimpleForm>
  </Create>
);

export default UserRelationshipCreate;
