import { gql, useMutation } from "@apollo/client";
import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  TextInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  useNotify,
  Button,
} from "react-admin";

export const UserList = (props: ResourceComponentProps) => (
  <List
    {...props}
    bulkActionButtons={<UserListActions />}
    filters={
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SelectInput
          source="role"
          choices={[
            { id: "CLIENT", name: "Client" },
            { id: "AGENT", name: "Agent" },
            { id: "ADMIN", name: "Admin" },
            { id: "SUPERUSER", name: "Superuser" },
          ]}
        />
        <ReferenceInput
          label="Organization"
          reference="Organization"
          source="organizationId"
        >
          <AutocompleteInput />
        </ReferenceInput>
        <ReferenceInput label="Region" reference="Region" source="regionId">
          <AutocompleteInput />
        </ReferenceInput>
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="role" />
      <TextField source="email" />

      <ReferenceArrayField
        sortable={false}
        sort={{ field: "name", order: "ASC" }}
        label="Regions"
        reference="Region"
        source="regionsIds"
      >
        <SingleFieldList>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        sortable={false}
        sort={{ field: "name", order: "ASC" }}
        label="Organizations"
        reference="Organization"
        source="organizationsIds"
      >
        <SingleFieldList>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ReferenceArrayField>

      <DateField source="activationEmailSentAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

const UserListActions = ({ selectedIds }: any) => {
  const notify = useNotify();
  const [
    sendActivationEmails,
    { loading: sendingActivationEmails },
  ] = useMutation(
    gql`
      mutation sendCaregiverActivationEmails($userIds: [ID!]!) {
        sendCaregiverActivationEmails(userIds: $userIds)
      }
    `
  );

  return (
    <>
      <Button
        label="Send PRO Activation Emails"
        disabled={sendingActivationEmails}
        variant="outlined"
        onClick={async () => {
          try {
            await sendActivationEmails({ variables: { userIds: selectedIds } });
            notify("Sent PRO activation emails");
          } catch (error) {
            console.error(error);
            notify(
              `An error occurred sending pro activation emails: ${error.message}`,
              "error"
            );
          }
        }}
      />
    </>
  );
};
