import React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
} from "react-admin";

export const AlayaCareOvertimeRuleCreate = (props: ResourceComponentProps) => (
  <Create
    {...props}
    title="Create AlayaCare Overtime Rule"
    successMessage="AlayaCare Overtime Rule created."
  >
    <SimpleForm>
      <ReferenceInput
        label="AlayaCareEnvironment"
        reference="AlayaCareEnvironment"
        source="alayaCareEnvironmentId"
      >
        <SelectInput optionText="baseApiUrl" validate={[required()]} />
      </ReferenceInput>
      <NumberInput source="hours" validate={[required()]} />
      <NumberInput source="days" validate={[required()]} />
    </SimpleForm>
  </Create>
);
