import React from "react";
import {
  Edit,
  NumberInput,
  ReferenceField,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextField,
} from "react-admin";
import { hoursToMilliseconds, millisecondsToHours } from "../../helpers/dates";

export const AlayaCareCancelCodeEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceField
        label="AlayaCare Environment"
        source="alayaCareEnvironment.id"
        reference="AlayaCareEnvironment"
      >
        <TextField source="baseApiUrl" />
      </ReferenceField>
      <NumberInput source="alayaCareCancelCodeId" validate={[required()]} />
      <NumberInput
        source="minDuration"
        label="Min duration (hours)"
        validate={[required()]}
        format={millisecondsToHours}
        parse={hoursToMilliseconds}
      />
      <NumberInput
        source="maxDuration"
        label="Max duration (hours)"
        validate={[required()]}
        format={millisecondsToHours}
        parse={hoursToMilliseconds}
      />
    </SimpleForm>
  </Edit>
);
