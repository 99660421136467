import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  FunctionField,
  DateField,
} from "react-admin";
import { printUser } from "../../helpers/print-user";

export const ClientBookingHourCreditTransactionList = (
  props: ResourceComponentProps
) => (
  <List
    {...props}
    title="Booking Hour Credit Transactions"
    bulkActionButtons={false}
    filters={
      <Filter {...props}>
        <ReferenceInput
          label="Client"
          reference="User"
          source="clientUserId"
          filter={{ role: "CLIENT" }}
          filterToQuery={(q: string) => ({ q })}
        >
          <AutocompleteInput optionText={printUser} />
        </ReferenceInput>
      </Filter>
    }
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="amount" />
      <TextField source="description" />
      <ReferenceField label="Client" reference="User" source="client.id">
        <FunctionField render={printUser} />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
