import { gql, useMutation } from "@apollo/client";
import React from "react";
import {
  Button,
  Edit,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  TopToolbar,
  useNotify,
} from "react-admin";
import { printOvertimeRule } from "../../helpers/print-overtime-rule";
import { printUser } from "../../helpers/print-user";

export const AlayaCareEmployeeEdit = (props: ResourceComponentProps) => (
  <Edit
    {...props}
    actions={<EmployeeActions />}
    transform={(values) => {
      return {
        ...values,
        overtimeRule: values.overtimeRule ?? { id: null },
      };
    }}
  >
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <ReferenceField
        label="AlayaCare Environment"
        source="alayaCareEnvironment.id"
        reference="AlayaCareEnvironment"
      >
        <TextField source="baseApiUrl" />
      </ReferenceField>
      <ReferenceField
        label="Linked Agent User"
        source="user.id"
        reference="User"
      >
        <FunctionField render={printUser} />
      </ReferenceField>
      <TextField source="alayaCareEmployeeId" label="AlayaCare employee id" />
      <TextField
        source="alayaCareEmployeeExternalId"
        label="AlayaCare employee external id"
      />
      <OvertimeRuleInput />
    </SimpleForm>
  </Edit>
);

const OvertimeRuleInput = (props: any) => {
  return (
    <ReferenceInput
      label="Overtime Rule"
      source="overtimeRule.id"
      reference="AlayaCareOvertimeRule"
      filter={{ alayaCareEnvironmentId: props.record.alayaCareEnvironment.id }}
      allowEmpty
    >
      <SelectInput optionText={printOvertimeRule} />
    </ReferenceInput>
  );
};

const EmployeeActions = (props: any) => {
  const notify = useNotify();
  const [syncEmployeeVisits, { loading: syncingEmployeeVisits }] = useMutation(
    gql`
      mutation syncAlayaCareEmployeeVisits($ids: [ID!]!) {
        syncAlayaCareEmployeeVisits(ids: $ids)
      }
    `
  );

  return (
    <TopToolbar>
      <Button
        disabled={syncingEmployeeVisits}
        variant="outlined"
        label="Sync AlayaCare visits"
        onClick={async () => {
          try {
            await syncEmployeeVisits({ variables: { ids: [props.data.id] } });
            notify("Queued AlayaCare visit sync for employee");
          } catch (error) {
            console.error(error);
            notify("An error occurred syncing employee visits", "error");
          }
        }}
      />
    </TopToolbar>
  );
};
