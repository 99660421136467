import React from "react";
import {
  AutocompleteInput,
  Create,
  PasswordInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from "react-admin";

const AlayaCareEnvironmentCreate = (props: ResourceComponentProps) => (
  <Create
    {...props}
    title="Create AlayaCare Environment"
    successMessage="AlayaCare Environment created."
  >
    <SimpleForm>
      <ReferenceInput
        label="Organization"
        reference="Organization"
        source="organizationId"
      >
        <AutocompleteInput validate={[required()]} />
      </ReferenceInput>

      <TextInput
        source="baseApiUrl"
        validate={[required()]}
        placeholder="https://example.alayacare.com"
      />
      <PasswordInput
        source="publicAccessKey"
        label="API public access key"
        validate={[required()]}
      />

      <PasswordInput
        source="privateAccessKey"
        label="API private access key"
        validate={[required()]}
      />

      <TextInput
        source="schedulerQueueUrl"
        validate={[required()]}
        placeholder="https://sqs.REGION.amazonaws.com/ACCOUNT/QUEUE"
      />
      <PasswordInput
        source="schedulerQueuePublicAccessKey"
        label="Scheduler queue public key"
        validate={[required()]}
      />
      <PasswordInput
        source="schedulerQueuePrivateAccessKey"
        label="Scheduler queue private key"
        validate={[required()]}
      />

      <TextInput
        source="employeeQueueUrl"
        validate={[required()]}
        placeholder="https://sqs.REGION.amazonaws.com/ACCOUNT/QUEUE"
      />
      <PasswordInput
        source="employeeQueuePublicAccessKey"
        label="Employee queue public key"
        validate={[required()]}
      />
      <PasswordInput
        source="employeeQueuePrivateAccessKey"
        label="Employee queue private key"
        validate={[required()]}
      />

      <TextInput
        source="patientQueueUrl"
        validate={[required()]}
        placeholder="https://sqs.REGION.amazonaws.com/ACCOUNT/QUEUE"
      />
      <PasswordInput
        source="patientQueuePublicAccessKey"
        label="Patient queue public key"
        validate={[required()]}
      />
      <PasswordInput
        source="patientQueuePrivateAccessKey"
        label="Patient queue private key"
        validate={[required()]}
      />

      <TextInput
        source="flatFileS3BucketName"
        label="Availability flat file S3 bucket"
        placeholder="s3-bucket-name"
      />
      <TextInput
        source="flatFileRegion"
        label="Availability flat file AWS region"
        placeholder="ca-central-1"
      />
      <PasswordInput
        source="flatFilePublicAccessKey"
        label="Availability flat file public key"
      />
      <PasswordInput
        source="flatFilePrivateAccessKey"
        label="Availability flat file private key"
      />
    </SimpleForm>
  </Create>
);

export default AlayaCareEnvironmentCreate;
