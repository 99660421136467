import React from "react";
import {
  Datagrid,
  TextField,
  List,
  ResourceComponentProps,
  Filter,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  BooleanField,
  FunctionField,
  DateField,
} from "react-admin";
import { printUser } from "../../helpers/print-user";

export const UserRelationshipList = (props: ResourceComponentProps) => (
  <List
    {...props}
    filters={
      <Filter {...props}>
        <ReferenceInput
          label="User"
          reference="User"
          source="userId"
          filterToQuery={(q: string) => ({ q })}
        >
          <AutocompleteInput optionText={printUser} />
        </ReferenceInput>
        <ReferenceInput
          label="Related User"
          reference="User"
          source="relatedUserId"
          filterToQuery={(q: string) => ({ q })}
        >
          <AutocompleteInput optionText={printUser} />
        </ReferenceInput>
      </Filter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="User" reference="User" source="user.id">
        <FunctionField render={printUser} />
      </ReferenceField>
      <ReferenceField
        label="Related User"
        reference="User"
        source="relatedUser.id"
      >
        <FunctionField render={printUser} />
      </ReferenceField>
      <BooleanField source="favorited" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
