import { Auth } from "aws-amplify";
import { AuthProvider } from "react-admin";

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const res = await Auth.signIn(username, password);
    console.log("signed in", res);
  },
  // called when the user clicks on the logout button
  logout: async () => {
    return Auth.signOut();
  },
  // called when the API returns an error
  checkError: async (error) => {
    if (error instanceof Error && error.message.includes("Not signed in")) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    return Auth.currentSession().then(() => {});
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => Promise.resolve(),
};

export default authProvider;
