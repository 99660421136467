import React from "react";
import {
  AutocompleteInput,
  Create,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ALL_TIMEZONES } from "../../constants/timezones";

const RegionCreate = (props: ResourceComponentProps) => (
  <Create {...props} successMessage="Region created.">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <AutocompleteInput
        source="timeZone"
        allowEmpty
        choices={ALL_TIMEZONES.map((timezone) => ({
          id: timezone,
          name: timezone,
        }))}
      />
    </SimpleForm>
  </Create>
);

export default RegionCreate;
